<template>
  <div class="filter-title d-flex justify-content-between align-items-center mb-md-4 mb-3 mt-4">
    <div>
      <!-- <h4 v-if="!title && title != ''"> -->
      <!-- <h4 v-if="!title && title != ''">
        <skeleton-loader-vue :height="23" :width="308" type="string" />
      </h4> -->

      <!-- <h4 v-if="$auth.setting.is_delivery_zone_available === 'Yes'">
        {{ $auth.setting.home_page_category_title || "" }}
      </h4>
      <p v-if="$auth.setting.is_delivery_zone_available === 'Yes' && $auth.setting.home_page_sub_title" class="mb-0">
        {{ $auth.setting.home_page_sub_title }}
      </p> -->

      <!-- <h4 v-if="this.$auth.setting.is_delivery_zone_available == 'Yes'">{{
        $auth.setting && $auth.setting.home_page_category_title && $auth.setting.home_page_category_title ?
          $auth.setting.home_page_category_title
          : "" }}</h4>
      <p v-if="this.$auth.setting && this.$auth.setting.is_delivery_zone_available == 'Yes'" class="mb-0">{{
        $auth.setting && $auth.setting.home_page_sub_title &&
          $auth.setting.home_page_sub_title ?
          $auth.setting.home_page_sub_title : "" }}</p> -->
      <h4>{{ title }}</h4>
      <!-- <h4>{{ subtitle }}</h4> -->
      <p class="mb-0">{{ subtitle }}</p>
    </div>
    <slot />
  </div>
</template>
<script>
import VueSkeletonLoader from 'skeleton-loader-vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    'skeleton-loader-vue': VueSkeletonLoader
  },
  props: ['title', 'subtitle'],

  computed: {
    ...mapGetters("setting", ["getDeliveryZoneSettings"]),
  },
}
</script>